<template>
    <b-row>
        <b-col cols="12" class="mb-32">
            <page-title />
        </b-col>
        <div class="magazine-container">
            <b-card>
                <div class="breadcrumb-custom">
                    <b-breadcrumb :items="breadcrumbItems"></b-breadcrumb>
                </div>
                <b-form>
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-xl-4"> <b-form-group id="input-group-2" label="เลขที่ใบเสร็จ"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="receiptNo" placeholder="กรอกเลขที่ใบเสร็จ"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-4 col-xl-4"> <b-form-group id="input-group-2" label="ชื่อ-สกุล"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="fullname" placeholder="กรอกชื่อ-สกุล"
                                    required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-4 col-xl-4">
                            <b-form-group id="input-group-2" label="วันที่ออกใบเสร็จ" label-for="input-2">
                                <b-form-input :id="`type-date`" :type="`date`" v-model="issueDate"
                                    required></b-form-input>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-xl-4"> <b-form-group id="input-group-2" label="ที่อยู่"
                                label-for="input-2">
                                <textarea id="textarea" class="text-area-custom col-sm-12 col-md-12 col-xl-12" v-model="address"
                                    placeholder="กรอกที่อยู่" rows="4" max-rows="6" cols="50" required>
                                </textarea>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-4 col-xl-4"> <b-form-group id="input-group-2" label="จำนวนเงิน"
                                label-for="input-2">
                                <b-form-input id="input-2" v-model="amount" placeholder="กรอกจำนวนเงิน"
                                type="number" step="0.1" max="99999999.99" required></b-form-input>
                            </b-form-group></div>
                        <div class="col-sm-12 col-md-4 col-xl-4"> <b-form-group id="input-group-2"
                                label="ชื่อผู้รับเงิน" label-for="input-2">
                                <b-form-input id="input-2" v-model="payneeName" placeholder="กรอกชื่อ-สกุล ผู้รับเงิน"
                                    required></b-form-input>
                            </b-form-group></div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 col-md-4 col-xl-4">  
                            <b-form-group id="input-group-2" label="หน่วยงานสำหรับออกใบเสร็จ:" label-for="input-2">
                                    <b-form-select v-model="institutionId" :options="institutionList" required>
                                    </b-form-select>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="submit-container">
                        <b-button variant="outline-black-100" v-on:click=(saveReceipts())>บันทึก</b-button>
                    </div>
                </b-form>


            </b-card>
        </div>
    </b-row>
</template>

<script>
import { BRow, BCol, BCard, BButton, BForm, BFormGroup, BFormCheckboxGroup, BFormCheckbox, BFormSelect, BFormInput, BAlert, BSpinner, BModal, BBreadcrumb } from "bootstrap-vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import receiptsService from "../../../../../services/receipts.service";
import moment from "moment-timezone";
import institutionService from "../../../../../services/institution.service";

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BButton,
        BForm,
        BFormGroup,
        BFormCheckboxGroup,
        BFormCheckbox,
        BFormSelect,
        BFormInput,
        BAlert,
        BSpinner,
        BModal,
        BBreadcrumb,
        PageTitle
    },
    data() {
        return {
            receiptId: null,
            receiptNo: null,
            fullname: null,
            address: null,
            issueDate: null,
            amount: 0,
            payneeName: null,
            institutionId: null,
            institution: null,
            institutionList: [],
            show: true,
            isLoading: false,
            timeZone: "Asia/Bangkok",
            errors: [],
            breadcrumbItems: [
                {
                    text: "การชำระเงิน",
                    href: "#",
                },
                {
                    text: "ใบเสร็จรับเงิน",
                    to: { name: "receipts" },
                },
                {
                    text: "เพิ่มใบเสร็จรับเงิน",
                    active: true,
                },
            ],
        };
    },

    methods: {
        getCurrentDate() {
            let currentDate = moment(this.dateNow).tz(this.timeZone).add(543, 'y').format('yyyy-MM-DD');
            this.issueDate = currentDate;
        },
        getInstitution: async function () {
            let response = await institutionService.getInstitution();
            let data = response.data.institutions;
            this.institutionList.push({ value: null, text: "กรุณาเลือกหน่วยงาน" });
            data.forEach((item) => {
                let newR = {
                    value: item.id,
                    text: item.name_th + " " + `(${item.name_eng})`
                }
                this.institutionList.push(newR);
            });
        },
        saveReceipts: async function () {
            this.isLoading = true;
            if (this.isValidate()) {
                let newR = {
                    institution_id: this.institutionId,
                    receipt_no: this.receiptNo,
                    fullname: this.fullname,
                    address: this.address,
                    issue_date: this.issueDate + " " + "00:00:00",
                    amount: this.amount,
                    paynee_name: this.payneeName,
                }
                await receiptsService.addReceipts(newR);
                history.back();
            } else {
                this.isLoading = false;
                this.$bvModal
                    .msgBoxConfirm("กรุณาตรวจสอบข้อมูลอีกครั้ง", {
                        cancelVariant: "text",
                        okTitle: "ตกลง",
                        size: "sm",
                    })
                    .then((value) => {
                        this.boxOne = value;
                    })
            }

        },
        isValidate() {
            this.errors = [];
            if (!this.institutionId) this.errors.push("Institution required.");
            if (!this.receiptNo) this.errors.push("Receipt No. required.");
            if (!this.fullname) this.errors.push("Full name required.");
            if (!this.address) this.errors.push("Address required.");
            if (!this.issueDate) this.errors.push("Issue date required.");
            if (!this.amount) this.errors.push("Amount required.");
            if (!this.payneeName) this.errors.push("Paynee name required.");

            if (!this.errors.length) {
                return true;
            }
            return false;
        },

    },
    mounted() {
        this.getCurrentDate();
        this.getInstitution();
    }

};
</script>